export default function filter_form($form, $results, callback, process) {
    let state = false;
    let auto = true;
    let pagination;
    let start_page = 1;
    let then = r => r;
    let clearExcept;

    if (typeof $results === 'object' && !($results instanceof $)) {
        if ($results.state) {
            state = $results.state;
        }

        if ($results.auto !== undefined) {
            auto = $results.auto;
        }

        if ($results.pagination) {
            pagination = $results.pagination;
        }

        if ($results.start_page !== undefined) {
            start_page = $results.start_page;
        }

        if ($results.then) {
            then = $results.then;
        }

        if ($results.clearExcept) {
            clearExcept = $results.clearExcept;
        }

        callback = $results.callback;
        process = $results.process;
        $results = $results.results;
    }

    if (!$results) {
        $results = $('#ajax-results');
    }

    $form.each(function () {
        let $f = $(this);
        let $p;
        let current_page = start_page;
        let page_count;
        let remain = true;
        let has_paginator = !!$f.data('paginator');

        function updatePagination(page, pages) {
            if (page === current_page && pages === page_count) {
                return;
            }

            if ($p) {
                $p.twbsPagination('destroy');
                $p.twbsPagination({
                    startPage: page,
                    totalPages: pages,
                    onPageClick: function (event, page) {
                        fetch(page);
                    }
                });
            }

            current_page = page;
            page_count = pages;
        }

        let overwrite_data;

        function fetch(page, append) {
            let data = overwrite_data || $f.serializeArray();
            let params = data.slice();

            if (pagination) {
                data.push({name: 'take', value: pagination});
            }

            if (page && page !== 1) {
                if (pagination) {
                    data.push({name: 'skip', value: (page - 1) * pagination});
                } else {
                    data.push({name: 'page', value: page});
                }
            }

            remain = true;

            return $.get($f.attr('action'), data)
                .then(then)
                .then(function (r) {
                    let $r = $(r.dom);

                    if (process) {
                        $r = process($results, $r, append);
                    }

                    remain = r.remain;

                    if (!append && clearExcept) {
                        $results.children().not(clearExcept).remove();
                    }

                    if (append || clearExcept) {
                        $results.append($r);
                    } else {
                        $results.html($r);
                    }

                    updatePagination(page, r.totalPages);

                    if (callback) {
                        callback(r);
                    }

                    if (state !== false) {
                        switch (state) {
                            case 'replace':
                                history.replaceState({}, null, [
                                    ($f.attr('action') || location.href).split('?')[0],
                                    $.param((has_paginator ? data : params).filter(v => v.value))
                                ].join('?'));
                                break;
                            default:
                                throw new Error(`Unimplemented filterform state behaviour ${state}`);
                        }
                    }
                });
        }

        if (auto) {
            $f.on('change input', () => fetch(1));

            $f.on('overwrite_data', (e, data) => {
                overwrite_data = data;
                fetch(1);
            });

            $f.on('submit', () => false);
        } else {
            $f.on('submit', (e) => {
                e.preventDefault();
                fetch(1);
            });
        }

        if (has_paginator) {
            let d = $f.data();
            $p = $(d.paginator);
            updatePagination(d.page, d.pages);
        }

        let ll_offset = $results.data('lazy-load');

        if (ll_offset !== undefined) {
            let loading = false;
            let $window = $(window);

            function ll() {
                if (loading || !remain) {
                    return;
                }

                if ($window.height() + $window.scrollTop() > $results.offset().top + $results.height() - ll_offset) {
                    loading = true;
                    fetch(current_page + 1, true).then(() => loading = false)
                        .then(() => remain && ll());
                }
            }

            $window.on('scroll', ll);
        }
    });
}

export function submit_form($form, manip) {
    if (manip === undefined) {
        manip = function (v) {
            return v;
        };
    }

    $form.on('change', function () {
        location.href = manip(
            [
                location.href.split('#')[0].split('?')[0],
                $.param($(this).serializeArray().filter(v => v.value))
            ].join('?')
        );
    });
}