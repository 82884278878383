import $ from "jquery";

const minute = 1000 * 60;
const hour = minute * 60;
const day = hour * 24;

let timer = null

const handleCountdown = function (countDownDate, dateField, counter) {
    // Get today's date and time
    const now = new Date().getTime();

    // Find the distance between now and the count down date
    const distance = countDownDate - now;

    // If the count down is over
    if (distance < 0) {
        clearInterval(timer);
        dateField.style.visibility = 'hidden';
        counter.style.display = 'none';
    } else {
        // Time calculations for days, hours, minutes and seconds
        const days = Math.floor(distance / day);
        const hours = Math.floor((distance % day) / hour);
        const minutes = Math.floor((distance % hour) / minute);
        const seconds = Math.floor((distance % minute) / 1000);

        const countdown = {
            dagen: days,
            uur: hours,
            minuten: minutes,
            seconden: seconds
        };

        for (let key in countdown) {
            let $el = $('#' + key);
            // @todo enable once updateBorder works correctly
            // $el.attr("data-degrees", countdown[key]);
            $el.html(countdown[key]);
        }
        // @todo make this work correctly, updateBorder does not work correctly
        // calculatePercentage(countdown);
    }
}

const calculatePercentage = function (countdown) {
    const percentDays = (countdown.dagen / 365);
    const percentHours = (countdown.uur / 24);
    const percentMinutes = (countdown.minuten / 60);
    const percentSeconds = (countdown.seconden / 60);

    const percentages = {
        dagen: percentDays,
        uur: percentHours,
        minuten: percentMinutes,
        seconden: percentSeconds
    };

    for (let key in percentages) {
        let degs = percentages[key] * 360;
        updateBorder(key, degs);
    }
}

const updateBorder = function (key, degs) {
    const degrees = 90 + degs;
    const border = $("#border-" + key);

    if (degs <= 90) {
        const color = 'red';
        border.css(
            'background-image',
            'linear-gradient(-90deg, transparent 50%, #fff 50%),linear-gradient(' + degrees + 'deg, ' + color + ' 50%, #fff 50%)'
        );
    } else if (degs <= 180) {
        const color = 'orange';
        border.css(
            'background-image',
            'linear-gradient(-90deg, transparent 50%, #fff 50%),linear-gradient(' + degrees + 'deg, ' + color + ' 50%, #fff 50%)'
        );
    } else if (degs <= 270) {
        const color = '#82b870';
        border.css(
            'background-image',
            'linear-gradient(-90deg, transparent 75%, #fff 25%), linear-gradient(' + degrees + 'deg, ' + color + ' 75%, #fff 25%)'
        );
    } else if (degs >= 270) {
        const color = '#14375A';
        border.css(
            'background-image',
            'linear-gradient(-90deg, transparent 100%, #fff 0%), linear-gradient(' + degrees + 'deg, ' + color + ' 100%, #fff 5%)'
        );
    }
}

export default function Countdown()
{
    const counter = document.querySelector('.counter');
    const dateField = document.querySelector('.date');
    const dateTime = $(dateField).data('date');

    if (dateTime) {
        const countDownDate = new Date(dateTime.replace(/-/g, "/")).getTime();

        // Update the count down every 1 second
        timer = setInterval(handleCountdown, 1000, countDownDate, dateField, counter);
    }
}

