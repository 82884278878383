import $ from "jquery";

require('./helpers/jquery.validate');

/** ----------------------------------------
 Jquery form validation
 ---------------------------------------- */


/** ----------------------------------------
 Default options
 ---------------------------------------- */

const validation_options = {
    highlight: function (element) {
        $(element).closest('.input-group').addClass('is-invalid').removeClass('is-valid');
    },
    unhighlight: function (element) {
        $(element).closest('.input-group').removeClass('is-invalid').addClass('is-valid');
    },
    errorPlacement: function (error, element) {
        element.closest('.input-section').find('.error-label').append(error);
    },
};


/** ----------------------------------------
 Overwrite message
 ---------------------------------------- */

/**
 * Make required message empty on default
 */

$.extend($.validator.messages, {
    required: ""
});


/** ----------------------------------------
 Add rules
 ---------------------------------------- */

/**
 * Add custom validation rules
 */

$.validator.addMethod("laxEmail", function (value, element) {
    // allow any non-whitespace characters as the host part
    return this.optional(element) || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
}, 'Please enter a valid email address.');


/** ----------------------------------------
 Signup Form
 ---------------------------------------- */

const signupForm = $('#signup');
signupForm.validate($.extend(validation_options, {
    ignore: '',
    rules: {
        email: {
            laxEmail: true
        }
        , billingEmail: {
            laxEmail: true
        }
    },
    submitHandler: function () {
        $.ajax({
            url: signupForm.attr('action'),
            method: 'POST',
            data: signupForm.serialize()
        })
        .done(function(res) {
            signupForm[0].reset();
            $('.thanks-message').slideDown();
        });
    },
    invalidHandler: function (event, validator) {
        // 'this' refers to the form
        let errors = validator.numberOfInvalids();
        console.log(errors);
    }
}));

/** ----------------------------------------
 Newsletter Form
 ---------------------------------------- */

const newsletterForm = $('#newsletter');
newsletterForm.validate($.extend(validation_options, {
    ignore: '',
    rules: {
        email: {
            laxEmail: true
        },
        name: {
            required: true
        }
    },
    submitHandler: function () {
        $.ajax({
            url: newsletterForm.attr('action'),
            method: 'POST',
            data: newsletterForm.serialize()
        })
            .done(function(res) {
                newsletterForm[0].reset();
                $('.newsletter-thankyou').slideDown();
            });
    },
    invalidHandler: function (event, validator) {
        // 'this' refers to the form
        let errors = validator.numberOfInvalids();
        console.log(errors);
    }
}));

/** ----------------------------------------
 Contact Form
 ---------------------------------------- */

const contactForm = $('#contact');

contactForm.validate($.extend(validation_options, {
    ignore: '',
    rules: {
        email: {
            laxEmail: true
        }
    },
    submitHandler: function () {

        $.ajax({
            url: contactForm.attr('action'),
            method: 'POST',
            data: contactForm.serialize()
        }).fail(function (res) {
            // loginButtonImage.html(loginArrowsSvg);
            // if(res.error) {
            //     alert(res.msg);
            // }
        }).done(function (res) {
            contactForm.find('.card--signup').html('<div class="card"><div class="card__top">Bedankt, je ontvangt spoedig bericht van ons.</div></div>');
        });
    },
}));













