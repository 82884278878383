import $ from "jquery";
import jQuery from "jquery";
import Macy from "macy";

// export for others scripts to use
window.$ = $;
window.jQuery = jQuery;

const _ = require('lodash');

// set CSRF token for every AJAX request using jquery.cookie
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

import filterForm from './helpers/filterform';

filterForm($(".lazy-filter"));
filterForm($(".lazy-filter-course"));
filterForm($(".lazy-filter-search"), {
        auto: false,
        loader: $(".append_loader"),
        callback: function (res) {
            const hits = res.found;
            const singularPluralIs = hits === 1 ? 'is' : 'zijn';
            const singularPluralResults = hits === 1 ? 'zoekresultaat' : 'zoekresultaten';

            $('.singularPluralIs').text(singularPluralIs);
            $('.singularPluralResults').text(singularPluralResults);
            $('.totalHits').text(hits);
        }
    });

$(function () {
    var $hamburger = $(".hamburger");
    $hamburger.on("click", function (e) {
        $hamburger.toggleClass("is-active");
        $('.nav').find('.sf-menu').toggleClass("open");
        // Do something else, like open/close menu
    });
});

/** ----------------------------------------
 Formvalidation
 ---------------------------------------- */
require('./form_validation');

import Swiper from 'swiper/swiper-bundle';

$(function () {
    // var mySwiper = new Swiper('.swiper-container', {
    //     loop: true,
    //     speed: 800,
    //     autoplay: {
    //         delay: 6000,
    //     },
    //     navigation: {
    //         nextEl: '.swiper-button-next',
    //         prevEl: '.swiper-button-prev',
    //     },
    // });

    new Swiper('.swiper-container', {
        slidesPerView: 1,
        keyboard: {
            enabled: true,
        },
        autoplay: {
            delay: 6000,
            disableOnInteraction: false,
        },
        touchRatio: 0,
        speed: 800,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    $('.nav--input').find('button').hide();
    $('.nav--loop').on('click', function () {
        const $nav = $('.nav');
        $nav.toggleClass('is-open');
        if ($nav.hasClass('is-open')) {
            $nav.find('input').focus();
            $('.nav--input').find('button').fadeIn();
            $('.nav--input').css({'padding': '5px', 'height': 'auto'});
        } else {
            $('.nav--input').css('padding', '0');
            $('.nav--input').find('button').fadeOut();
        }
    });
});

if (document.getElementsByClassName('masonry').length > 0) {
    const macyInstance = Macy.init({
        container: '.masonry',
        columns: 3,
        margin: 10,
        onInit: true,
        breakAt: {
            768: {
                columns: 2
            },
            576: {
                columns: 1
            }
        }
    });

    setTimeout(function () {
        // this fixes issue with wrong calc margin
        macyInstance.reInit();
        macyInstance.container.style.opacity = 1;
    });
}

import {FormFilterClass} from './helpers/FormFilterClass'

new FormFilterClass({
    form: document.getElementById('cursusfilter'),
    container: document.getElementById('ajax-results'),
});

import {FormValidation} from './contact'

const forms = document.getElementsByClassName('form-validation');
if (forms.length !== 0) {
    for (let i = 0; i < forms.length; i++) {
        const form = forms[i];

        var options = {
            rules: {}
        };

        if ($(form).hasClass('sidebar-form')) {
           options = {
                rules: {
                    phone: {
                        require_from_group: [1, ".contact-group"]
                    },
                    email: {
                        require_from_group: [1, ".contact-group"]
                    },
                }
            };
        }

        new FormValidation(form, {
            allowUrlOverwrite: false,
            loading: '<i class="fa fa-spinner fa-spin"></i>',
            button: form.querySelector('button'),
            inputGroup: 'form-group',
            validatorOptions: options,
            afterSubmit: function () {
                let $form = $(form);
                let download_field = $form.find("input[name='download']");

                if (download_field) {
                    let file = download_field.val();
                    if (file) {
                        window.open(file, '_blank');
                    }
                }

                form.innerHTML = '';
                document.getElementById(form.dataset.after).style.display = "block";
            }
        });
    }
}

const courses = document.getElementsByClassName('course-validation');
if (courses.length !== 0) {
    for (let i = 0; i < courses.length; i++) {
        let course = courses[i];
        new FormValidation(course, {
            allowUrlOverwrite: false,
            loading: '<i class="fa fa-spinner fa-spin"></i>',
            button: course.querySelector('button'),
            inputGroup: 'form-group',
            afterSubmit: function () {
                course.innerHTML = '';
                document.getElementById(course.dataset.after).style.display = "block";
            },
        });

        course.querySelector('button').onclick = () => {
            if (!$(course).valid()) {
                alert('Nog niet alle velden zijn ingevuld, controleer het formulier');
            }
        };
    }
}

$('[data-course]').each(function (index, item) {
    let $item = $(item);
    let dataToggleTarget = $item.data('course');
    let $targets = $(`[data-course-target=${dataToggleTarget}]`);
    let current = $targets.eq(0);
    current.addClass('is-animate is-active');
    $item.on('change', function (e) {
        let $newItem = $(e.target);
        let newValue = $newItem.val();
        current.removeClass('is-active');
        setTimeout(function () {
            current.removeClass('is-animate');
            current = $(`[data-course-id=${newValue}]`);
            current.addClass('is-animate is-active');
        }, 200);
    });
});

import Countdown from "./countdown/countdown";
new Countdown();




let newsletter = document.getElementById('newsletter');

if (newsletter) {
    let domainField = document.querySelector('.selected-domain');
    let domain = $(domainField).data('selected-domain');

    if (domainField) {
        if (domain) {
            $('#newsletter option').each(function() {
                let words = domain.toLowerCase().split(" ");
                let optionText = this.text.toLowerCase();

                for (let i = 0; i < words.length; i++ ) {
                    if (words[i] !== 'en') {
                        if (optionText.indexOf(words[i]) !== -1) {
                            $(newsletter).val(this.value);
                        }
                    }
                }
            });
        }

        $(domainField).on('click', function () {
            let $container = $("html,body");
            let $scrollTo = $(newsletter);

            $container.animate({
                scrollTop: $scrollTo.offset().top - $container.offset().top + $container.scrollTop(),
                scrollLeft: 0
            },1000);
        });
    }
}

const jobOpportunity= document.getElementById('job-opportunity-form');

if (jobOpportunity)
{
    new FormValidation(jobOpportunity, {
        allowUrlOverwrite: false,
        loading: '<i class="fa fa-spinner fa-spin"></i>',
        button: jobOpportunity.querySelector('button'),
        inputGroup: 'form-group',
        getAjaxPayload: (form) => {
            let fd = new FormData(jobOpportunity);
            let cv = document.getElementById('file');
            fd.append( 'file', cv.files[0]);

            return {
                url: form.attr('action'),
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
            }
        },
        afterSubmit: function () {
            jobOpportunity.innerHTML = '';
            document.getElementById(jobOpportunity.dataset.after).style.display = "block";
        }
    });
}

let scrollTrigger = document.getElementsByClassName('trigger-scroll');
let scrollTo = document.getElementsByClassName('scroll-to');

if (scrollTrigger.length && scrollTo.length) {
    $(scrollTrigger).click(function() {
        $([document.documentElement, document.body]).animate({
            scrollTop: ($(scrollTo).offset().top - 70)
        }, 3000);
    });
}

/** ----------------------------------------
 Accordion
 ---------------------------------------- */
let accordions = document.getElementsByClassName("accordion__top");

if (accordions.length) {
    let animating = false;
    let delay = 500;

    for (let i = 0; i < accordions.length; i++) {
        let item = accordions[i];
        let accordion = $(item);

        accordion.on('click', function () {
            let $this = $(this);

            if (!animating) {
                if ($this.hasClass('is-collapsed')) {
                    hideAccordion($this);
                } else {
                    showAccordion($this);
                }
            }
        })
    }

    function hideAccordion(el) {
        animating = true;
        el.removeClass('is-collapsed');
        let bottom = el.siblings('.accordion__bottom');
        bottom.slideUp(delay);

        setTimeout(() => {
            animating = false;
        }, 800);
    }

    function showAccordion(el) {
        animating = true;
        el.addClass('is-collapsed');
        let bottom = el.siblings('.accordion__bottom');
        bottom.slideDown(delay);

        setTimeout(() => {
            animating = false;
        }, 800);
    }
}
