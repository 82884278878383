import $ from "jquery";

require('./helpers/jquery.validate');
/* Adds Element AFTER NeighborElement */

Element.prototype.appendAfter = function (element) {
    element.parentNode.insertBefore(this, element.nextSibling);
}, false;

/** ----------------------------------------
 Overwrite message
 ---------------------------------------- */

/**
 * Make required message empty on default
 */

$.extend($.validator.messages, {
    required: ""
});


/** ----------------------------------------
 Add rules
 ---------------------------------------- */

/**
 * Add custom validation rules
 */

$.validator.addMethod("email", function (value, element) {
    return this.optional(element) || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
}, '');


$.validator.addMethod("youtube", function (value, element) {

    // if id element exist
    let youtube_id_element = document.querySelector('[name=youtube_id]');
    if (youtube_id_element === null) {

        // if does not exist make new one
        let new_youtube_id_element = document.createElement("input");
        new_youtube_id_element.setAttribute('type', 'hidden');
        new_youtube_id_element.setAttribute('name', 'youtube_id');
        new_youtube_id_element.appendAfter(element);
        youtube_id_element = document.querySelector('[name=youtube_id]');
    }

    if (value) {
        let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
        let match = value.match(regExp);
        if (match && match[2].length === 11) {
            youtube_id_element.setAttribute('value', match[2]);
            return true;
        }
        youtube_id_element.setAttribute('value', '');
        return false;
    }
    youtube_id_element.setAttribute('value', '');
    return true
}, '');

$.validator.addMethod( "require_from_group", function( value, element, options ) {
    var $fields = $( options[ 1 ], element.form ),
        $fieldsFirst = $fields.eq( 0 ),
        validator = $fieldsFirst.data( "valid_req_grp" ) ? $fieldsFirst.data( "valid_req_grp" ) : $.extend( {}, this ),
        isValid = $fields.filter( function() {
            return validator.elementValue( this );
        } ).length >= options[ 0 ];

    // Store the cloned validator for future validation
    $fieldsFirst.data( "valid_req_grp", validator );

    // If element isn't being validated, run each require_from_group field's validation rules
    if ( !$( element ).data( "being_validated" ) ) {
        $fields.data( "being_validated", true );
        $fields.each( function() {
            validator.element( this );
        } );
        $fields.data( "being_validated", false );
    }
    return isValid;
}, $.validator.format( "Please fill at least {0} of these fields." ) );

if (location.href.split('#')[0].split('?')[1]) {
    const $list = location.href.split('#')[0].split('?')[1].split('&');
    let test = {};
    for (let $item of $list) {
        let split = $item.split('=');
        test[split[0]] = split[1];
    }
}

function UrlToObject() {
    let list = {};
    if (location.href.split('#')[0].split('?')[1]) {
        const $list = location.href.split('#')[0].split('?')[1].split('&');

        for (let $item of $list) {
            let split = $item.split('=');
            list[split[0]] = split[1];
        }
        return list;
    }
    return list
}

export class FormValidation {


    constructor(element, options = {}) {


        this.element = element;
        this.Form = $(element);

        const defaults = {

            /**
             *  Overwrite default validator options
             */
            validatorOptions: {},

            /**
             * allow url params to prefil inputs
             */
            allowUrlOverwrite: false,

            /**
             * class if all obligations are correct
             */
            validClass: 'is-valid',

            /**
             *  Class if an obligation is not met
             */
            invalidClass: 'is-invalid',


            /**
             *  Parent of input field
             */
            inputGroup: 'form__group',

            /**
             * Loader html
             */
            loading: 'Loading...',


            /**
             * Button element
             */
            button: '',

            /**
             * Prefill data from blade
             */

            prefill: {},


            /**
             * function called after submit
             */
            afterSubmit: false,

            getAjaxPayload: function (form) {
                return {
                    url: form.attr('action'),
                    method: 'POST',
                    data: form.serialize(),
                }
            }
        };

        if (this.element.dataset.default) {
            defaults.prefill = JSON.parse(this.element.dataset.default);
        }


        // Set Configuration variable
        let conf = $.extend({}, options);
        this.conf = $.extend(true, {}, defaults, conf);


        if (this.conf.allowUrlOverwrite) {
            this.conf.prefill = $.extend(true, {}, this.conf.prefill, UrlToObject());
        }

        this.init();
    }


    /** ----------------------------------------
     Error placement
     ---------------------------------------- */

    setValidationOptions() {
        const _this = this;

        this.ValidationOptions = Object.assign({
            highlight: function (element) {
                $(element).closest('.' + _this.conf.inputGroup).addClass(_this.conf.invalidClass).removeClass(_this.validClass);
            },
            unhighlight: function (element) {
                $(element).closest('.' + _this.conf.inputGroup).removeClass(_this.conf.invalidClass).addClass(_this.validClass);
            },
            errorPlacement: function (error, element) {
                $(element).closest('.input-section').find('.error-label').append(error);
            },
        }, this.conf.validatorOptions);

    }


    setLoader() {
        const button = this.conf.button;
        button.style.width = button.offsetWidth;
        this.button_text = button.innerHTML;
        button.innerHTML = this.conf.loading;
    }

    isLoaded() {
        this.Form[0].reset();
        this.removeLoader();
        if (this.conf.afterSubmit) {
            this.conf.afterSubmit();
        }
    }

    isError() {
        this.conf.button.innerHTML = '<i class="fa fa-times demo-class"></i>';

        setTimeout(() => {
            this.removeLoader();
        }, 4000)
    }

    removeLoader() {
        this.conf.button.innerHTML = this.button_text;
        this.conf.button.style.width = '';
    }


    setValidation() {
        const _this = this;

        _this.Form.validate($.extend(_this.ValidationOptions, {
            submitHandler: () => {
                let _this = this;

                // if form has Recaptcha
                if (this.Form.find('[data-sitekey]').length) {
                    let hiddenField = this.Form.find('[name ="hiddenRecaptcha"]');

                    if (hiddenField.val()) {
                        this.Form.find('.g-recaptcha div div').css('border', 'none');
                    } else {
                        this.Form.find('.g-recaptcha div div').css('border', '1px solid red');
                        return false
                    }
                }
                this.setLoader();
                let payload = this.conf.getAjaxPayload(this.Form);

                $.ajax(payload).done(function (res) {
                    setTimeout(() => {
                        _this.isLoaded();
                    }, 1000);

                }).fail(function (res) {
                    _this.isError();
                });
            },
            // errorPlacement: function (error, element) {
            //     alert(error.text());
            // }
        }));
    }

    toggleWatcher() {
        const _this = this;
        this.Form.find("[data-toggle]").on('change', (e) => {
            let target = e.target,
                name = target.getAttribute('name'),
                value = target.value,
                all_targets = _this.element.querySelectorAll("[data-toggle-target]");

            if (target.getAttribute('type') === 'checkbox') {
                value = target.checked ? '1' : '0';
            }

            for (let i = 0; i < all_targets.length; i++) {
                let element = all_targets[i];
                if (element.getAttribute('data-toggle-target') === name) {
                    element.classList.remove('toggle--is-active');
                    if (element.getAttribute('data-toggle-value') === value || element.getAttribute('data-toggle-value') === '') {
                        element.classList.add('toggle--is-active');
                    } else {
                        element.classList.remove('toggle--is-active');
                    }
                }
            }
        });
    }

    defaultValue() {
        let _this = this;
        const list = _this.conf.prefill;
        Object.keys(list).forEach(function (key) {

            let items = _this.element.querySelectorAll(`[name=${key}]`);

            if (!items.length) return false;

            let TYPE = items[0].getAttribute('type');
            let VALUE = list[key];


            if (TYPE === 'radio') {
                for (let i = 0; i < items.length; i++) {
                    let div = items[i];
                    if (div.getAttribute('value') === VALUE) {
                        div.checked = true;
                        div.dispatchEvent(new Event('change'));
                    }
                }
            }

            if (TYPE === 'text') {
                items[0].value = VALUE;
            }
        });

    }

    init() {
        this.setValidationOptions();
        this.setValidation();
        this.toggleWatcher();
        this.defaultValue();
    }
}
