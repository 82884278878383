export class FormFilterClass {
    constructor(options) {

        const defaults = {
            form: false,
            container: false,
            lazyload: false,
            startpage: 1,

        };

        let conf = $.extend({}, options);
        this.conf = $.extend(true, {}, defaults, conf);
        this.loading = false;

        this.remain = true;
        this.window = $(window);
        this.conf.currentpage = this.conf.startpage;

        this.init();
        this.then = r => r;
    }

    fetch(append) {
        let page = this.conf.currentpage;
        let _this = this;
        let $f = $(this.conf.form);
        let data = $f.serializeArray();

        if (page && page !== 1) {
            data.push({name: 'page', value: page});
        }

        $.get(this.conf.form.getAttribute('action'), data)
            .then(function (r) {
                let $r = r.dom;
                _this.remain = r.remain;
                if (append) {
                    _this.conf.container.innerHTML += $r;
                } else {
                    if (!$r)
                        _this.conf.container.innerHTML = '';
                    else
                        _this.conf.container.innerHTML = $r;
                }

                _this.finish_loading();
            })
    }

    start_loading() {
        this.loading = true;
        if (this.conf.loader)
            this.conf.loader.style.display = 'block';
    }

    finish_loading() {
        this.loading = false;
        if (this.conf.loader)
            this.conf.loader.style.display = 'none';
    }

    updatePage() {
        this.conf.currentpage++;
    }


    lazyloader() {
        const rect = this.conf.container.getBoundingClientRect();
        if (this.loading || !this.remain) {
            return;
        }
        if ((this.window.height() + this.window.scrollTop()) > (rect.height - this.conf.lazyload)) {
            this.start_loading();
            this.updatePage();
            this.fetch(true);
        }
    }

    onchange() {
        this.conf.container.innerHTML = '';
        this.start_loading();
        this.conf.currentpage = 1;
        this.fetch(false);
    }

    init() {
        if (this.conf.form && this.conf.container) {

            if (this.conf.loader)
                this.conf.loader.style.display = 'none';

            let $f = $(this.conf.form);

            $f.on('change', () => {
                this.onchange();
            });

            if (this.conf.lazyload) {
                this.window.on('scroll', this.lazyloader.bind(this));
            }
        }
    }
}